import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  isAuthenticated: false,
  token: null,
  user: null,
  patients: [],
  selectedPatientId: null,
  appointments: [],
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthToken(state, action) {
      state.token = action.payload;
      state.isAuthenticated = !!action.payload;
    },
    // Ação atualizada para limpar completamente o estado
    clearAuthToken(state) {
      Object.assign(state, initialState);
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setPatients(state, action) {
      state.patients = action.payload;
    },
    setSelectedPatientId(state, action) {
      state.selectedPatientId = action.payload;
    },
    setAppointments(state, action) {
      const newAppointments = action.payload;
      const existingAppointments = state.appointments;
      state.appointments = [...existingAppointments.filter(appt => !newAppointments.some(newAppt => newAppt.id === appt.id)), ...newAppointments];
    },
  },
});

// Seletor que encontra o patientId baseado no userId
export const selectPatientIdByUserId = (state, userId) => {
  const patient = state.auth.patients.find(p => p.userId === userId);
  return patient ? patient.id : null;
};

const persistConfig = {
  key: 'auth',
  storage,
};

export const { setAuthToken, clearAuthToken, setUser, setPatients, setSelectedPatientId, setAppointments } = authSlice.actions;
export default persistReducer(persistConfig, authSlice.reducer);