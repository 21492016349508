// src/layout/MainLayout/Drawer/DrawerContent/Navigation.js

import { Box, Typography } from '@mui/material';
import NavGroup from './NavGroup';
import menuItem from '../../../../../menu-items';

const Navigation = ({ handleOpenNewPatientDialog }) => {
  const navGroups = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        // Passar handleOpenNewPatientDialog para NavGroup
        return <NavGroup key={item.id} item={item} handleOpenNewPatientDialog={handleOpenNewPatientDialog} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu item não reconhecido
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;