// project import
import pacientes from './pacientes';
import agenda from './agenda';
import configuracoes from './configuracoes';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [agenda, pacientes, configuracoes]
};

export default menuItems;