// assets
import { CalendarOutlined, AreaChartOutlined, DesktopOutlined  } from '@ant-design/icons';

// icons
const icons = {
  CalendarOutlined,
  AreaChartOutlined,
  DesktopOutlined
};

// ==============================|| MENU ITEMS - AGENDA ||============================== //

const agenda = {
  id: 'agenda',
  title: 'Menu',
  type: 'group',
  children: [
    {
      id: 'agenda-principal',
      title: 'Agenda',
      type: 'item',
      url: '/agenda',
      icon: icons.CalendarOutlined
    },
    {
      id: 'dashboard',
      title: 'Meu consultório',
      type: 'item',
      url: '/dashboard',
      icon: icons.DesktopOutlined
    },
    {
      id: 'pagamentos',
      title: 'Meus pagamentos',
      type: 'item',
      url: '/meus-pagamentos',
      icon: icons.AreaChartOutlined
    }
  ]
};

export default agenda;