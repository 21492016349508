// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';

// auth
import auth from './auth';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
    // outros reducers
    menu,
    auth
  });
  
  export default reducers;
