
export const IsAuth = (token) => {
  try {

      const parse_token = JSON.parse(atob(token.split(".")[1]));
      const limit_timestamp = parse_token.exp
  
      if (Date.now()/1000 < limit_timestamp){
          return true 
      }
        else {
            return false
        }
    

  } catch (e) {
    //console.log(e)
    return [false, null];
  }
};

export const IsPaymentPeriodValid = (dateString) => {

  // Parse the input date string
  const inputDate = new Date(dateString);

  // Get today's date
  const today = new Date();

  // Set today's date to the same time as the input date
  today.setHours(inputDate.getHours(), inputDate.getMinutes(), inputDate.getSeconds());
  
  // Subtract one day from today's date
  const oneDayAgo = new Date(today);
  oneDayAgo.setDate(today.getDate() - 1);
  //console.log(inputDate > oneDayAgo)
  // Compare the input date with today's date minus one day
  if (inputDate > oneDayAgo) {
      return true
  } else {
      return false 
  }
};