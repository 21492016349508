// src/pages/patients/NewPatientDialog.js

import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogContent, DialogTitle, TextField, Grid, Divider, FormControlLabel, Checkbox, Button, ToggleButtonGroup, ToggleButton,
  Select, MenuItem, Typography, IconButton
} from '@mui/material';
import { CloseOutlined } from '@ant-design/icons';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Snackbar, Alert } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setPatients } from '../../store/reducers/auth';
import { useNavigate } from 'react-router-dom';
import {IsAuth} from '../../utils/auth_verifier'

const BASE_URL = process.env.REACT_APP_BASE_URL;

function validaCPF(cpf) {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
  let soma = 0;
  for (let i = 0; i < 9; i++) soma += parseInt(cpf.charAt(i)) * (10 - i);
  let resto = (soma * 10) % 11;
  if ((resto === 10) || (resto === 11)) resto = 0;
  if (resto !== parseInt(cpf.charAt(9))) return false;
  soma = 0;
  for (let i = 0; i < 10; i++) soma += parseInt(cpf.charAt(i)) * (11 - i);
  resto = (soma * 10) % 11;
  if ((resto === 10) || (resto === 11)) resto = 0;
  if (resto !== parseInt(cpf.charAt(10))) return false;
  return true;
}


const NewPatientDialog = ({ open, onClose }) => {
  const token = useSelector(state => state.auth.token);
  const navigate  = useNavigate();
  const [isResponsible, setIsResponsible] = useState(false);
  const [appointmentType, setAppointmentType] = useState('Particular');
  const [convenioNome, setConvenioNome] = useState(null);
  const dispatch = useDispatch();

  // checa se token esta valido
  const checkAuthAndRedirect = async () => {
      console.log('Checking tokekn')
      if (!token) {
          navigate('/login');
          return false;
      }
      const isAuth = await IsAuth(token);
      if (!isAuth) {
          navigate('/login');
          return false;
      }
      return true;
    };
 


  // Estado para controlar a Snackbar
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  // Função para abrir a Snackbar
  const openSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  // Função para fechar a Snackbar
  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const TextMaskCustomCPF = React.memo((props) => (
    <MaskedInput {...props} mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]} placeholder='Digite o CPF' />
  ));

  const TextMaskCustomPhone = React.memo((props) => (
    <MaskedInput {...props} mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} placeholder='Digite o número de telefone' />
  ));

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(255, 'O nome completo deve ter no máximo 255 caracteres')
      .required('O nome completo é obrigatório'),
    email: Yup.string()
      .email('E-mail inválido'),
    cpf: Yup.string()
      .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
      .test('valida-cpf', 'CPF inválido', value => value ? validaCPF(value) : true),
    telefone: Yup.string()
      .required('Telefone é obrigatório')
      .transform(value => value.replace(/[^\d]/g, ''))
      .test(
        'len',
        'O telefone deve ter no mínimo 10 dígitos',
        value => !value || (value && value.length >= 10)
      ),
    responsible_name: Yup.string()
      .max(255, 'O nome completo deve ter no máximo 255 caracteres'),
    responsible_cpf: Yup.string()
      .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido', true)
      .test('valida-cpf', 'CPF inválido', value => value ? validaCPF(value) : true),    
    birth_date: Yup.date()
      .nullable(),
    appointment_value: Yup.string()
      .matches(/^\d+(,\d{1,2})?$/, 'O valor da consulta deve ser um número com até duas casas decimais após a vírgula'),
    
    // Adicione validações para os demais campos conforme necessário
  });  

  const handleSubmit = async (values, actions) => {
    // Primeiro, forçamos a validação do formulário
    const errors = await actions.validateForm(values);
  
    // Verificamos se existem erros
    if (Object.keys(errors).length === 0) {
    // Se o formulário é válido, prosseguimos com a lógica de submissão

    const { appointmentType, convenioName, isResponsible, ...restOfValues } = values;

    let birthDateFormatted = null;
    if (restOfValues.birth_date) {
        const birthDateParts = restOfValues.birth_date.split('-');
        birthDateFormatted = `${birthDateParts[2]}/${birthDateParts[1]}/${birthDateParts[0]}`; // Formata a data para DD/MM/AAAA
    }

    const appointmentValue = values.appointment_value.replace(',', '.');
    const processedValues = {
      ...restOfValues,
      email: restOfValues.email || null,
      cpf: restOfValues.cpf ? parseInt(restOfValues.cpf.replace(/[^\d]/g, ""), 10) : null,
      telefone: restOfValues.telefone ? parseInt(restOfValues.telefone.replace(/[^\d]/g, ""), 10) : null,
      health_insurance: appointmentType === 'Particular' ? null : convenioName,
      responsible_cpf: restOfValues.responsible_cpf ? parseInt(restOfValues.responsible_cpf.replace(/[^\d]/g, ""), 10) : null,
      responsible_phone: restOfValues.responsible_phone ? parseInt(restOfValues.responsible_phone.replace(/[^\d]/g, ""), 10) : null,
      birth_date: restOfValues.birth_date ? birthDateFormatted : null,
      appointment_value: restOfValues.appointment_value ? parseFloat(appointmentValue) : null,
      gender: restOfValues.gender || null,
      responsible_name: restOfValues.responsible_name || null,
      // Adicione lógica para processar outros campos, se necessário
    };

    console.log('Dados Input:', processedValues);

    const auth = await checkAuthAndRedirect(); // <----- check se paciente esta logado antes de realizar a request
    if (!auth) return;

    try {
      const response = await axios.post(BASE_URL + '/patients/register', processedValues, {
        headers: { Authorization: `Bearer ${token}` }
      });

      console.log("Dados Response API", response)

      switch (response.data.status_code) {
        case 201:
          actions.resetForm();
          openSnackbar('Paciente cadastrado com sucesso!', 'success');

          // Nova lógica para solicitar a lista atualizada de pacientes
          try {
            const patientInfoResponse = await axios.get(BASE_URL + '/patients', {
              headers: { Authorization: `Bearer ${token}` }
            });
            console.log('patientInfoResponse:', patientInfoResponse.data);

            if (patientInfoResponse.data) {
              const patients = patientInfoResponse.data.map(({ patient_id, name }) => ({
                id: patient_id,
                name,
              }));
        
              dispatch(setPatients(patients));
              console.log('patients:', patients);
            }
          } catch (error) {
            console.error('Erro ao obter a lista de pacientes:', error);
            // Você pode optar por exibir uma notificação de erro aqui, se desejar
          }
          break;
        case 409:
          openSnackbar('Paciente já está cadastrado!', 'warning');
          break;
        case 500:
          openSnackbar('Erro interno do servidor!', 'error');
          break;
        default:
          openSnackbar('Ocorreu um erro inesperado.', 'info');
      }
    } catch (error) {
      console.error(error);
      actions.setErrors({ submit: error.message });
      openSnackbar('Erro ao processar o cadastro!', 'error');
    } finally {
      actions.setSubmitting(false);
    }
  } else {
    console.log('Erros de Validação:', errors);
    openSnackbar('Por favor, corrija os erros antes de submeter o formulário.', 'error');
  }
   // Desativa o estado de submissão
   actions.setSubmitting(false);
};

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle style={{ fontSize: '18px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative' }}>
        Cadastrar Novo Paciente
        <IconButton onClick={onClose} style={{ position: 'absolute', right: '8px', top: '8px' }}>
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
      <Formik
        initialValues={{
          name: '',
          email: '',
          cpf: '',
          telefone: '',
          gender: '',
          appointment_value: '',
          appointmentType: 'Particular',
          convenioName: '',
          isResponsible: false,
          responsible_name: '',
          responsible_cpf: '',
          responsible_phone: '',
          birth_date: '' // Adicione outros campos iniciais conforme necessário
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting, values, setFieldValue }) => (
          <Form autoComplete="off">
            <Grid container spacing={3}>
              {/* Nome Completo */}
              <Grid item xs={12}>
                <Typography variant="body1">Nome Completo</Typography>
                <Field
                  name="name"
                  as={TextField}
                  fullWidth
                  variant="outlined"
                  placeholder="Digite o nome completo do paciente"
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                />
              </Grid>

              {/* E-mail */}
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">E-mail</Typography>
                <Field
                  name="email"
                  as={TextField}
                  fullWidth
                  variant="outlined"
                  placeholder="Digite o e-mail do paciente"
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />
              </Grid>

              {/* CPF */}
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">CPF</Typography>
                <Field
                  name="cpf"
                  as={TextField}
                  fullWidth
                  variant="outlined"
                  InputProps={{ inputComponent: TextMaskCustomCPF }}
                  error={touched.cpf && !!errors.cpf}
                  helperText={touched.cpf && errors.cpf}
                />
              </Grid>

              {/* Telefone */}
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">Telefone</Typography>
                <Field
                  name="telefone"
                  as={TextField}
                  fullWidth
                  variant="outlined"
                  InputProps={{ inputComponent: TextMaskCustomPhone }}
                  error={touched.telefone && !!errors.telefone}
                  helperText={touched.telefone && errors.telefone}
                />
              </Grid>

              {/* Data de Nascimento */}
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">Data de Nascimento</Typography>
                <Field
                  name="birth_date"
                  as={TextField}
                  fullWidth
                  type="date"
                  placeholder="Selecione uma data"
                  InputLabelProps={{ shrink: true }}
                  error={touched.birth_date && !!errors.birth_date}
                  helperText={touched.birth_date && errors.birth_date}
                />
              </Grid>

              {/* Gênero */}
              <Grid item xs={6} sm={6}>
                <Typography variant="body1">Gênero</Typography>
                <Field
                  name="gender"
                  as={Select}
                  fullWidth
                  displayEmpty
                  error={touched.gender && !!errors.gender}
                  helperText={touched.gender && errors.gender}
                >
                  <MenuItem value=""><em>Selecione o Gênero</em></MenuItem>
                  <MenuItem value="Masculino">Masculino</MenuItem>
                  <MenuItem value="Feminino">Feminino</MenuItem>
                  <MenuItem value="Não-binário">Não-binário</MenuItem>
                  <MenuItem value="Outros">Outros</MenuItem>
                </Field>
              </Grid>

              {/* Tipo de Atendimento */}
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">Tipo de Atendimento</Typography>
                <ToggleButtonGroup
                  value={values.appointmentType}
                  exclusive
                  onChange={(event, newAppointmentType) => setFieldValue('appointmentType', newAppointmentType)}
                  fullWidth
                >
                  <ToggleButton value="Particular" style={{ height: '40px' }}>Particular</ToggleButton>
                  <ToggleButton value="Convênio" style={{ height: '40px' }}>Convênio</ToggleButton>
                </ToggleButtonGroup>
              </Grid>

              {/* Valor da Consulta */}
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">Valor da Consulta</Typography>
                <Field
                  name="appointment_value"
                  as={TextField}
                  fullWidth
                  variant="outlined"
                  placeholder="Digite o valor da consulta"
                  error={touched.appointment_value && !!errors.appointment_value}
                  helperText={touched.appointment_value && errors.appointment_value}
                />
              </Grid>

              {/* Nome do Convênio */}
              {values.appointmentType === 'Convênio' && (
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">Nome do Convênio</Typography>
                  <Field
                    name="convenioName"
                    as={TextField}
                    fullWidth
                    variant="outlined"
                    placeholder="Digite o nome do convênio de saúde"
                    error={touched.convenioName && !!errors.convenioName}
                    helperText={touched.convenioName && errors.convenioName}
                  />
                </Grid>
              )}

              {/* Possui Responsável */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Field type="checkbox" name="isResponsible" as={Checkbox} />}
                  label="Possui Responsável?"
                />
              </Grid>

              {/* Campos do Responsável */}
              {values.isResponsible && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="body1">Nome do Responsável</Typography>
                    <Field
                      name="responsible_name"
                      as={TextField}
                      fullWidth
                      variant="outlined"
                      placeholder="Digite o nome completo do responsável"
                      error={touched.responsible_name && !!errors.responsible_name}
                      helperText={touched.responsible_name && errors.responsible_name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">CPF do Responsável</Typography>
                    <Field
                      name="responsible_cpf"
                      as={TextField}
                      fullWidth
                      variant="outlined"
                      InputProps={{ inputComponent: TextMaskCustomCPF }}
                      error={touched.responsible_cpf && !!errors.responsible_cpf}
                      helperText={touched.responsible_cpf && errors.responsible_cpf}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Telefone do Responsável</Typography>
                    <Field
                      name="responsible_phone"
                      as={TextField}
                      fullWidth
                      variant="outlined"
                      InputProps={{ inputComponent: TextMaskCustomPhone }}
                      error={touched.responsible_phone && !!errors.responsible_phone}
                      helperText={touched.responsible_phone && errors.responsible_phone}
                    />
                  </Grid>
                </>
              )}
              {/* Botões de Ação */}
              <Grid item container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={onClose}
                    style={{ color: 'red', borderColor: 'red', textTransform: 'none' }}
                    disabled={isSubmitting}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  startIcon={isSubmitting ? <CircularProgress size={24} color="inherit" /> : null}
                >
                  {isSubmitting ? 'Carregando...' : 'Adicionar'}
                </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </DialogContent>
    {/* Snackbar para feedback */}
    <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.severity}
          variant="filled" // Estilo filled aplicado
          sx={{ 
            width: '100%',
            fontSize: '0.90rem', // Aumentando o tamanho da fonte
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
  </Dialog>
  );
};

export default React.memo(NewPatientDialog);