// assets
import { UnorderedListOutlined, UserAddOutlined } from '@ant-design/icons';

// icons
const icons = {
  UnorderedListOutlined,
  UserAddOutlined
};

// ==============================|| MENU ITEMS - PACIENTES ||============================== //

const pacientes = {
  id: 'pacientes',
  title: 'Pacientes',
  type: 'group',
  children: [
    {
      id: 'pacientes-lista',
      title: 'Meus Pacientes',
      type: 'item',
      url: '/paciente-lista',
      icon: icons.UnorderedListOutlined
    },
    {
      id: 'pacientes-novo',
      title: 'Novo Paciente',
      type: 'item',
      url: '/paciente-novo',
      icon: icons.UserAddOutlined
    }
  ]
};

export default pacientes;