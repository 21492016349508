import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'; // Importe PersistGate
import { SnackbarProvider } from 'notistack'


// scroll bar
import 'simplebar/dist/simplebar.css';

// apex-chart
import './assets/third-party/apex-chart.css';

// project import
import App from './App';
import reportWebVitals from './reportWebVitals';

// Importe o persistor da sua configuração da store
import { store, persistor } from './store';

// Import Bootstrap CSS (Full Calendar)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // needs additional webpack config!

// Sentry
import * as Sentry from "@sentry/react";

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const sentryDsn = process.env.REACT_APP_SENTRY_DSN
Sentry.init({
  dsn: sentryDsn,
  integrations: [],
});

// GTM Part 1: Inject the GTM script into the <head>
const injectGTM = (gtmId) => {
  if (!gtmId) return;

  const script = document.createElement('script');
  script.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${gtmId}');
  `;
  document.head.insertBefore(script, document.head.childNodes[0]);
};

injectGTM('GTM-NMWP9VWS');

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}> {/* Adicione o PersistGate aqui */}
        <BrowserRouter basename="/">
        <SnackbarProvider autoHideDuration={10000} maxSnack={5}>
          {/* GTM Part 2: noscript fallback */}
          <noscript>
            <iframe src={`https://www.googletagmanager.com/ns.html?id=GTM-NMWP9VWS`}
                    height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}>
            </iframe>
          </noscript>
          {/* Your app component */}
          <App />
        </SnackbarProvider>
        </BrowserRouter>
      </PersistGate>
    </ReduxProvider>
  </StrictMode>
);
reportWebVitals();