import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import LegalRoutes from './LegalRoutes';
import ConfirmationRoute from './ConfirmationRoute'
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, LoginRoutes, LegalRoutes, ConfirmationRoute]);
}