import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MinimalLayout from '../layout/MinimalLayout';

const Static = Loadable(lazy(() => import('../pages/common/StaticHtml')));

// ==============================|| LEGAL ROUTING ||============================== //

const BASE_URL = process.env.REACT_APP_BASE_URL;

const renderStatic = (value) => <Static url={value} />;

const LegalRoutes = {
  path: '/legal/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'pp',
      element: renderStatic(BASE_URL + '/legal/pp')
    },
    {
      path: 'tc',
      element: renderStatic(BASE_URL + '/legal/tc')
    }
  ]
};

export default LegalRoutes;