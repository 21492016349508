// assets
import {
    SettingOutlined,
    ProfileOutlined,
    ReadOutlined
  } from '@ant-design/icons';
  
  // icons
  const icons = {
    SettingOutlined,
    ProfileOutlined,
    ReadOutlined
  };
  
  // ==============================|| MENU ITEMS - CONFIGURACOES ||============================== //
  
  const configuracoes = {
    id: 'configuracoes',
    title: 'Configurações',
    type: 'group',
    children: [
      {
        id: 'perfil',
        title: 'Perfil',
        type: 'item',
        url: '/perfil',
        icon: icons.SettingOutlined
      },
      {
        id: 'faq',
        title: 'Ajuda',
        type: 'item',
        url: '/faq',
        icon: icons.ReadOutlined
      },
    ]
  };
  
  export default configuracoes;  