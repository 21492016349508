// project import
import Routes from './routes';
import ThemeCustomization from './themes';
import ScrollTop from './components/ScrollTop';
import { useEffect } from 'react';

// Hotjar
import Hotjar from '@hotjar/browser';

const App = () => {

  useEffect(() => {
    const hotjarSiteID = process.env.REACT_APP_HOTJAR_SITE_ID
    const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION
    if (hotjarSiteID != null && hotjarVersion != null) {
      Hotjar.init(parseInt(hotjarSiteID, 10), parseInt(hotjarVersion, 10));
    }
  }, [])

  return (
    <ThemeCustomization>
      <ScrollTop>
        <Routes />
      </ScrollTop>
    </ThemeCustomization>
  );
};

export default App;