import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import navigation from '../../menu-items';
import Breadcrumbs from '../../components/@extended/Breadcrumbs';

// types
import { openDrawer } from '../../store/reducers/menu';

// Dialog
import NewPatientDialog from '../../pages/patients/NewPatientDialog';

// axios
import axios from 'axios';

// notistack
import { enqueueSnackbar } from 'notistack'

// date-fns
import { utcToZonedTime } from 'date-fns-tz';
import { format, parse } from 'date-fns';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  let lastDateTimeVerifiedUpdates = new Date();

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = useSelector((state) => state.auth.token);

  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();

  const { drawerOpen } = useSelector((state) => state.menu);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  const [isNewPatientDialogOpen, setIsNewPatientDialogOpen] = useState(false);

  // Função para abrir o diálogo de Novo Paciente
  const handleOpenNewPatientDialog = () => {
    setIsNewPatientDialogOpen(true);
  };

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  const fetchData = async () => {

    if (!token) {
      return;
    }

    let saoPauloZone = 'America/Sao_Paulo';
    let zonedDate = utcToZonedTime(lastDateTimeVerifiedUpdates, saoPauloZone);
    let formattedDateTime = format(zonedDate, 'yyyy-MM-dd HH:mm:ss', { timeZone: saoPauloZone });

    try {
      let response = await axios.get(`${BASE_URL}/appointment/fetchLatestUpdates`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          updated_after: formattedDateTime
        }
      });

      let appoints = response.data.appointments || [];

      lastDateTimeVerifiedUpdates = new Date();

      if (appoints.length > 0) {
        appoints.forEach(appointment => {
          let date = parse(appointment.start_datetime, "yyyy-MM-dd HH:mm:ss", new Date());
          enqueueSnackbar(`Sua consulta com ${appointment.patient_name} em ${format(date, 'dd-MM-yyyy')} às ${format(date, 'HH:mm')} foi modificada. Atualize sua agenda para ver a mudança.`, {
            preventDuplicate: false,
            variant: 'info',
          });
        });
      }
    } catch (error) {
      console.error('Failed to fetch latest updates:', error);
      // ignore error so user experience is not affected
    }

  };

  useEffect(() => {

    // Set up an interval to fetch data every 30 seconds
    const intervalId = setInterval(fetchData, 30000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer
      open={open}
      handleDrawerToggle={handleDrawerToggle}
      handleOpenNewPatientDialog={handleOpenNewPatientDialog}
      />
      <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        <Toolbar />
        {/* <Breadcrumbs navigation={navigation} title /> */}
        <Outlet />
      </Box>
      <NewPatientDialog
      open={isNewPatientDialogOpen}
      onClose={() => setIsNewPatientDialogOpen(false)}
      />
    </Box>
  );
};

export default MainLayout;