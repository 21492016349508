import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';

// import private router
import PrivateRoute from './PrivateRoute';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard')));
const MyDay = Loadable(lazy(() => import('../pages/dashboard/MyDay')));
const MyAppointmentsAndPayments = Loadable(lazy(() => import('../pages/dashboard/MyAppointmentsAndPayments')));
const UserProfile = Loadable(lazy(() => import('../pages/user/UserProfile')));
const Agenda = Loadable(lazy(() => import('../pages/calendar/CalendarComponent')));
const ListaPacientes = Loadable(lazy(() => import('../pages/patients/PacientsList')));
const FichaPacientes = Loadable(lazy(() => import('../pages/patients/PacientsDetail')));
const FAQ = Loadable(lazy(() => import('../pages/suport/PsMindFAQ')));
const RefreshAfterPayment = Loadable(lazy(() => import('../pages/payments/RedirectAndRefreshAfterPayment')));
const Assinatura = Loadable(lazy(() => import('../pages/payments/PaymentPlansStripe')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: (
        <PrivateRoute>
          <DashboardDefault />
        </PrivateRoute>
      ),
    },
    {
      path: '/dashboard', // Rota explícita para '/dashboard'
      element: (
        <PrivateRoute>
          <DashboardDefault />
        </PrivateRoute>
      ),
    },
    {
      path: '/meu-dia', // Rota explícita para '/dashboard'
      element: (
        <PrivateRoute>
          <MyDay />
        </PrivateRoute>
      ),
    },
    {
      path: '/meus-pagamentos', // Rota explícita para '/dashboard'
      element: (
        <PrivateRoute>
          <MyAppointmentsAndPayments />
        </PrivateRoute>
      ),
    },
    {
      path: '/perfil', // Nova rota para o perfil do usuário
      element: (
        <PrivateRoute>
          <UserProfile />
        </PrivateRoute>
      ),
    },
    {
      path: '/agenda', // Nova rota para o perfil do usuário
      element: (
        <PrivateRoute>
          <Agenda />
        </PrivateRoute>
      ),
    },
    {
      path: '/paciente-lista', // Nova rota para o perfil do usuário
      element: (
        <PrivateRoute>
          <ListaPacientes />
        </PrivateRoute>
      ),
    },
    {
      path: '/paciente-ficha', // Nova rota para o perfil do usuário
      element: (
        <PrivateRoute>
          <FichaPacientes />
        </PrivateRoute>
      ),
    },
    {
      path: '/faq', // Nova rota para o perfil do usuário
      element: (
        <PrivateRoute>
          <FAQ />
        </PrivateRoute>
      ),
    },
    {
      path: '/assinatura', // Nova rota para o perfil do usuário
      element: (
        <PrivateRoute>
          <Assinatura />
        </PrivateRoute>
      ),
    },
      {
      path: '/bem-vindo', // Nova rota para o perfil do usuário
      element: (
        <PrivateRoute>
          <RefreshAfterPayment />
        </PrivateRoute>
      ),
    },
    // ... outras rotas
  ],
};


export default MainRoutes;