import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import reducers from './reducers';

// Configuração do Persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'] // Aqui, 'auth' é o nome do slice que você quer persistir. Adicione mais se necessário.
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer
});

const persistor = persistStore(store);

export { store, persistor };