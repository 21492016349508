import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MinimalLayout from '../layout/MinimalLayout';

const ConfirmacaoAgendamentosPacientes = Loadable(lazy(() => import('../pages/patient_appointment_confirmation/PatientConfirmation')));

// ==============================|| LEGAL ROUTING ||============================== //


const ConfirmationRoute = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/confirmacao/:id/:date',
      element: 
          <ConfirmacaoAgendamentosPacientes />
      
    }
  ]
};

export default ConfirmationRoute;